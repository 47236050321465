import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { LodopPrint, request } from 'egenie-common';
import JsBarcode from 'jsbarcode';
import { action, observable } from 'mobx';
import QRCode from 'qrcode';
import React from 'react';
import { render } from 'react-dom';
import api from '../../../utils/api';
import { qrCodeTag } from './component';
import type { PrintItem, UniqueCodeInfo } from './types';
import type { Template } from '../../showroomManage/components/print/types';

const SECOND_LEVEL_DOMAIN = 'https://dap111.ejingling.cn';

class PrintStore {
  constructor() {
    this.lodopPrint = new LodopPrint();
    this.lodopPrint.init(true);
  }

  @observable public lodopPrint: LodopPrint;

  @observable public visible = false;

  @observable private goodsIds: string | number;

  @observable private ref: FormInstance;

  @observable private printType: '0' | '1' | '2' = '0'; // 打印类型

  @observable private printInfo: Template = null;

  @observable private templateData: Array<{ name; alias; code; }> = [];

  private queryPrintTemplate = async() => {
    const res: BaseData<Template> = await request({
      url: `${api.getPosTemplateInfoByType}/3`,
      method: 'GET',
    });
    this.printInfo = res.data;
    const printTemplate = [];
    res.data.rowDetails?.forEach((row) => {
      row.details.forEach((detail) => {
        printTemplate.push({
          name: detail.name,
          alias: detail.alias,
          code: detail.code,
        });
      });
    });
    this.templateData = printTemplate;
  };

  // 唯一码节点
  private uniqueCodeTag = (uniqueTag: {
    qrCode: string;
    uniqueCode: string;
    color?: string;
    size?: string;
    skuNo?: string;
    [propsName: string]: any;
  }): JSX.Element => {
    const {
      uniqueCode,
      color,
      size,
      skuNo,
      qrCode,
    } = uniqueTag;
    const fontSize = {
      fontSize: '10px',
      lineHeight: '12px',
      textAline: 'center',
    };
    const mb8 = { marginBottom: '8px' };
    return (
      <div style={{
        width: '40mm',
        height: '80mm',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <div style={{
          fontSize: '16px',
          textAlign: 'center',
          marginBottom: '10px',
        }}
        >
          唯一码
        </div>
        <div style={{ textAlign: 'center' }}>
          <img
            src={qrCode}
            style={{
              border: '1px solid #000',
              width: '110px',
              height: '110px',
            }}
          />
          <div style={{
            textAlign: 'center',
            paddingTop: '6px',
            fontSize: '10px',
            lineHeight: '10px',
          }}
          >
            {uniqueCode}
          </div>
        </div>
        <div style={{
          marginTop: '13px',
          padding: '0px 10px 0px 20px',
        }}
        >
          <div style={{
            ...fontSize,
            ...mb8,
          }}
          >
            {`SKU编码：${skuNo || ''}`}
          </div>
          <div style={{
            ...fontSize,
            ...mb8,
          }}
          >
            {`颜色：${color || ''}`}
          </div>
          <div style={{
            ...fontSize,
            ...mb8,
          }}
          >
            {`尺码：${size || ''}`}
          </div>
        </div>
      </div>
    );
  };

  @action public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };

  @action public onShow = (id: string | number) => {
    this.visible = true;
    this.goodsIds = id;
  };

  @action public onCancel = () => {
    this.visible = false;
    this.ref?.resetFields();
  };

  @action public onOk = () => {
    const { printType } = this.ref?.getFieldsValue();
    this.printType = printType;
    this.queryPrintData();
    this.onCancel();
  };

  // 初始化纸张大小
  private initPageSize = (intOrient: 1 | 2 = 1): void => {
    const strPageName = '',
      pageW = '40mm',
      pageH = '80mm',
      paddingTop = '0mm',
      paddingLeft = '0mm';
    this.lodopPrint.instance.PRINT_INITA(paddingTop, paddingLeft, pageW, pageH, strPageName);
    this.lodopPrint.instance.SET_PRINT_PAGESIZE(intOrient, pageW, pageH, strPageName);
  };

  // 请求打印数据
  private queryPrintData = async(): Promise<void> => {
    message.info('请求打印数据中，请稍候');
    const res: BaseData<PrintItem[]> = await request({
      url: api.queryPrintInfoByRecordId,
      method: 'GET',
      params: { recordId: this.goodsIds },
    });

    this.print(res?.data || [], this.printType);
  };

  // 打印
  /**
   * @param printData 打印数据
   * @param printType 0：打印吊牌码和唯一码；1：吊牌码；2唯一码
   */
  public print = (printData: PrintItem[], printType: '0' | '1' | '2' = '1'): void => {
    // 同时打印吊牌码和唯一码
    if (printType === '0') {
      this.printQrCodeAndUniqueCode(printData);
    } else if (printType === '1') { // 打印调牌码
      this.printQrCode(printData);
    } else { // 打印唯一码
      this.printUniCode(printData);
    }
  };

  // 打印吊牌码和唯一码
  private printQrCodeAndUniqueCode = async(printData: PrintItem[]): Promise<void> => {
    if (Array.isArray(printData)) {
      for (const item of printData) {
        const {
          uniqueCodeInfos,
          goodsId,
        } = item;
        if (Array.isArray(uniqueCodeInfos)) {
          for (const info of uniqueCodeInfos) {
            const { uniqueCode } = info;
            const qrCode = await this.createQrCode(goodsId, 'goodsId');
            const uniCode = await this.createQrCode(uniqueCode, 'unicode');
            await this.queryPrintTemplate();

            if (!this.templateData || this.templateData.length === 0) {
              message.warn('请配置吊牌码模板后再打印');
              return;
            }
            const qrCodeTagNode = qrCodeTag({
              qrCode,
              ...item,
            }, this.templateData);
            this.createRender(qrCodeTagNode, 1);
            const uniCodeTagNode = this.uniqueCodeTag({
              qrCode: uniCode,
              ...info,
            });
            this.createRender(uniCodeTagNode, 1);
          }
        }
      }
    }
  };

  // 打印吊牌
  private printQrCode = (printData: PrintItem[], printType?: '0' | '1') => {
    printData?.map(async(el) => {
      const qrCode = await this.createQrCode(el.goodsId, 'goodsId');
      await this.queryPrintTemplate();
      if (!this.templateData || this.templateData.length === 0) {
        message.warn('请配置吊牌码模板后再打印');
        return;
      }
      const node = qrCodeTag({
        qrCode,
        ...el,
      }, this.templateData);
      if (printType === '0') { // 如果是同时打印吊牌码和唯一码，则根据唯一码数量来打印
        el.uniqueCodeInfos?.forEach(() => {
          this.createRender(node, 1);
        });
      } else { // 如果只打印吊牌码， 每一个商品打印一张
        this.createRender(node, 1);
      }
    });
  };

  // 打印唯一码
  private printUniCode = (printData: PrintItem[]) => {
    printData?.map((el) => {
      el.uniqueCodeInfos?.map(async(info, index) => {
        const qrCode = await this.createQrCode(info.uniqueCode, 'unicode');
        const node = this.uniqueCodeTag({
          qrCode,
          ...info,
        });
        this.createRender(node, 1);
      });
    });
  };

  // create render
  private createRender = (printNode: any, intOrient: 1 | 2): void => {
    const tempNode = document.createElement('div');
    render((
      printNode
    ), tempNode, () => {
      this.initPageSize(intOrient);
      this.lodopPrint.instance.NEWPAGE();
      this.lodopPrint.instance.ADD_PRINT_HTM(0, 0, '40mm', '80mm', tempNode.innerHTML);
      this.lodopPrint.instance.PRINT();
    });
  };

  private createUniCodeRender = (uniqueCodeInfo: UniqueCodeInfo) => {
    const {
      uniqueCode,
      size,
      color,
      skuNo,
    } = uniqueCodeInfo;
    this.initPageSize(2);
    this.lodopPrint.instance.NEWPAGE();
    this.lodopPrint.instance.ADD_PRINT_BARCODE(12, 40, 260, 70, '128Auto', uniqueCode);
    this.lodopPrint.instance.ADD_PRINT_TEXT(90, 40, 60, 16, 'SKU编码:');
    this.lodopPrint.instance.ADD_PRINT_TEXT(90, 90, 300, 16, `${skuNo}\n`);
    this.lodopPrint.instance.ADD_PRINT_TEXT(110, 40, 50, 16, '颜色:');
    this.lodopPrint.instance.ADD_PRINT_TEXT(110, 70, 200, 16, `${color}\n`);
    this.lodopPrint.instance.ADD_PRINT_TEXT(130, 40, 50, 16, '尺码:');
    this.lodopPrint.instance.ADD_PRINT_TEXT(130, 70, 200, 16, `${size}\n`);
    this.lodopPrint.instance.PRINT();
  };

  // 获取barcode
  private createBarcode = (uniqueCodes: string): string => {
    // 生成barcode实例化容器
    const barcodeContainer = document.createElement('img');
    barcodeContainer.setAttribute('id', uniqueCodes);
    document.body.appendChild(barcodeContainer);
    JsBarcode(`#${uniqueCodes}`, uniqueCodes, {
      format: 'CODE128',
      displayValue: false,
      width: 5,
      height: 300,
      margin: 0,
      fontSize: 10,
      background: '#fff',
      lineColor: '#000',
    });

    // 获取barcode source
    const barcodeSource = barcodeContainer.getAttribute('src');

    // 移除barcode节点
    document.body.removeChild(barcodeContainer);
    return barcodeSource;
  };

  // 获取qrcode资源
  private createQrCode = async(targetId: number | string, qrCodeType: 'unicode' | 'goodsId'): Promise<string> => {
    let imgUrl = '';
    if (qrCodeType === 'goodsId') {
      imgUrl = await QRCode.toDataURL(`${SECOND_LEVEL_DOMAIN}/wx/shopkeeper/styleDetail?goodsId=${targetId}`);
    } else {
      imgUrl = await QRCode.toDataURL(targetId);
    }
    return Promise.resolve(imgUrl);
  };
}

export { PrintStore };
