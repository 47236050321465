import { ProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { PrintModal } from '../components/print';
import ReceivingRecordModel from './model';

@observer
export default class ReceivingRecord extends Component {
  constructor(props) {
    super(props);
    this.receivingRecordModel = new ReceivingRecordModel();
  }

  private readonly receivingRecordModel: ReceivingRecordModel;

  public render(): ReactNode {
    const { programme, printModel } = this.receivingRecordModel;
    return (
      <>
        <ProgrammeComponent store={programme}/>
        <PrintModal store={printModel}/>
      </>
    );
  }
}
