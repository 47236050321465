import React from 'react';

export const qrCodeTag = (tag: {
  qrCode: string;
  area: string;
  market: string;
  goodsNo: string;
  category: string;
  salePrice: number;
  maxSalePrice: number;
  [propsName: string]: any;
}, template?: Array<{ name; alias; code; }>): JSX.Element => {
  const {
    qrCode,
    area,
    market,
    goodsNo,
    category,
    salePrice,
    maxSalePrice,
    recommendedSalePrice, // 建议零售价
    goodsLivePrice, // 建议直播价
    brokerageRate, // 直播佣金比例
  } = tag;
  const fontSize = {
    fontSize: '10px',
    lineHeight: '16px',
  };
  const mb8 = { marginBottom: '8px' };
  const mb4 = { marginBottom: '4px' };
  return (
    <div style={{
      width: '40mm',
      height: '80mm',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <VisibleComponent visible={Boolean(qrCode)}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={qrCode}
            style={{
              border: '1px solid #000',
              width: '110px',
              height: '110px',
            }}
          />
          <div style={{
            textAlign: 'center',
            paddingTop: '6px',
            fontSize: '10px',
            lineHeight: '10px',
          }}
          >
            APP/微信扫码查看详情
          </div>
        </div>
      </VisibleComponent>
      <div style={{
        marginTop: '13px',
        padding: '0px 10px 0px 20px',
      }}
      >
        {template.map(({
          name,
          alias,
          code,
        }) => {
          if (code === 'qrCode') {
            return null;
          }
          return (
            <VisibleComponent
              key={code}
              visible={Boolean(tag[code])}
            >
              <div style={{
                ...fontSize,
                ...mb4,
              }}
              >
                {code !== 'salePrice' && code !== 'recommendedSalePrice' && code !== 'goodsLivePrice' ? `${alias || name}：${tag[code] || ''}` : code === 'salePrice' ? `${alias || name}：¥${maxSalePrice === salePrice ? salePrice : `${salePrice}~¥${maxSalePrice}` || ''}` : `${alias || name}：¥${tag[code] || ''}`}
              </div>
            </VisibleComponent>
          );
        })}
      </div>
    </div>
  );
};

export const VisibleComponent: React.FC<{ visible: boolean; }> = (props) => {
  const { visible } = props;
  if (!visible || !props.children) {
    return null;
  }

  return (
    <div>
      {props.children}
    </div>
  );
};
