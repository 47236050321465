import { Form, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { PrintStore } from './store';

export const PrintModal: React.FC<{ store: PrintStore; }> = observer((props) => {
  const { visible, setRef, onCancel, onOk } = props.store;
  return (
    <Modal
      className={styles.modal}
      onCancel={onCancel}
      onOk={onOk}
      open={visible}
      title="打印"
    >
      <div className={styles.title}>
        请选择需要打印的内容:
      </div>
      <Form
        initialValues={{ printType: '0' }}
        ref={setRef}
      >
        <Form.Item name="printType">
          <Radio.Group>
            <Radio value="0">
              吊牌码 + 唯一码
            </Radio>
            <Radio value="1">
              仅吊牌码
            </Radio>
            <Radio value="2">
              仅唯一码
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
});

