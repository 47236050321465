import type { BaseData, IMainSubStructureModel, PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, Programme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { getGalleryId } from '../../utils';
import api from '../../utils/api';
import { PrintStore } from '../components/print/store';
import type { GalleryGoodsArrival, QueryDetailsInterface } from './interface';

interface ShopInfo {
  id: string;
  shopNo: string;
  shopName: string;
}

export default class ReceivingRecordModel {
  constructor() {
    this.__init__();
    this.getShopList();
  }

  @observable public programme: Programme;

  @observable public printModel: PrintStore;

  @observable public galleryId = '';

  private getShopList = (shopNo = '') => {
    request<PaginationData<ShopInfo>>({
      url: api.getOperationShopList,
      method: 'POST',
      data: {
        page: 1,
        pageSize: 200,
        shopNo,
      },
    })
      .then((res) => {
        const shopOptions = this.mapOptions(res.data.list, 'id', 'shopNo');
        this.programme.filterItems.addDict({ shopId: shopOptions });
      })
      .catch((e) => {
        console.log('获取档口列表出错', e);
      });
  };

  @action public handleQuery = (params) => {
    const {
      filterParams,
      ...rest
    } = params;
    if (filterParams.dateValue) {
      filterParams.arrivalEndDate = filterParams?.dateValue?.split(',')[1];
      filterParams.arrivalBeginDate = filterParams?.dateValue?.split(',')[0];
      delete filterParams.dateValue;
      delete filterParams.dateType;
    }
    return request<PaginationData<Partial<GalleryGoodsArrival>>>({
      url: api.galleryGoodsArrivalQueryPage,
      method: 'POST',
      data: {
        ...filterParams,
        ...rest,
        galleryId: this.galleryId,
      },
    });
  };

  @observable private grid: IMainSubStructureModel = {
    buttons: [],
    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          formatter: ({ row }) => {
            return (
              <a onClick={async(e) => {
                e.stopPropagation();
                try {
                  this.programme.gridModel.gridModel.loading = true;
                  await this.printModel.onShow(row.galleryArrivalRecordId);
                } catch (e) {
                  console.error(e);
                } finally {
                  this.programme.gridModel.gridModel.loading = false;
                }
              }}
              >
                打印
              </a>
            );
          },
        },
        {
          key: 'arrivalType',
          name: '类型',
        },
        {
          key: 'galleryArrivalRecordNo',
          name: '到货单号',
          width: 300,
        },
        {
          key: 'arrivalNum',
          name: '到货数量',
        },

        {
          key: 'creator',
          name: '创建人',
        },
        {
          key: 'arrivalDate',
          name: '到货时间',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        width: 150,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'galleryArrivalRecordId',
      sortByLocal: false,
      showCheckBox: false,
      showSelectedTotal: false,
      showReset: false,
      showNormalEmpty: true,
      gridIdForColumnConfig: 'tsReceivingRecordMainTable',
    },
    subTables: {
      activeTab: 'documentsDetailed',
      tabsFlag: {
        inited: { documentsDetailed: true },
        searched: {},
      },

      list: [
        {
          tab: {
            name: '单据明细',
            value: 'documentsDetailed',
          },
          grid: {
            columns: [
              {
                key: 'goodsName',
                name: '商品名称',
              },
              {
                key: 'pic',
                name: '图片',
                width: '100',
                formatter: ({ row }) => {
                  return <ImgFormatter value={row.pic || noPic}/>;
                },
              },
              {
                key: 'goodsNo',
                name: '商品编码',
              },
              {
                key: 'goodsSkuNo',
                name: 'SKU编码',
              },
              {
                key: 'color',
                name: '颜色',
              },
              {
                key: 'size',
                name: '尺码',
              },
              {
                key: 'num',
                name: '数量',
              },
              {
                key: 'uniqueCode',
                name: '唯一码',
              },
              {
                key: 'shopName',
                name: '档口名称',
              },
              {
                key: 'mallSaleOrderNo',
                name: '订单号',
              },
            ].map((el) => {
              return {
                resizable: true,
                sortable: false,
                ...el,
              };
            }),
            rows: [],
            primaryKeyField: 'uniqueCode',
            sortByLocal: false,
            setColumnsDisplay: true,
            gridIdForColumnConfig: 'tsReceivingRecordDetailTable',
            showCheckBox: false,
            showSelectedTotal: false,
            showPagination: false,
            showNormalEmpty: true,
          },
          allFilterItemsInOneGroup: true,
          filterItems: [
            {
              label: '档口名称',
              field: 'shopName',
              value: '',
              type: 'input',
            },
            {
              label: '订单号',
              field: 'mallSaleOrderNo',
              value: '',
              type: 'input',
            },
            {
              label: '唯一码',
              field: 'uniqueCode',
              value: '',
              type: 'input',
            },
          ],
          api: {
            // @ts-ignore
            onQuery: ({
              data,
              cursorRow,
            }) => {
              const { galleryArrivalRecordId } = cursorRow;
              return request<BaseData<QueryDetailsInterface[]>>({
                url: api.galleryGoodsArrivalQueryDetails,
                method: 'POST',
                data: {
                  galleryArrivalRecordId,
                  ...data?.cond,
                },
              })
                .then((req) => {
                  return {
                    status: 'Successful',
                    code: 0,
                    data: { list: req.data || []},
                  };
                });
            },
          },
        },
      ],
    },

    api: { onQuery: this.handleQuery },
  };

  public mapOptions = (data: ShopInfo[], key, value) => {
    return Array.isArray(data) ? data.map((item) => ({
      label: item[value],
      value: item[key],
    })) : [];
  };

  @action
  private __init__ = async() => {
    this.programme = new Programme({
      gridModel: new MainSubStructureModel(this.grid),
      filterItems: [
        {
          type: 'date',
          field: 'dateType',
          label: '日期类型',
          data: [
            {
              label: '到货时间',
              value: 'time',
            },
          ],
          allowClear: true,
          selectValue: 'time',
        },
        {
          type: 'input',
          label: '商品名称',
          field: 'goodsName',
        },
        {
          type: 'input',
          label: '商品唯一码',
          field: 'uniqueCode',
        },
        {
          type: 'select',
          label: '档口名称',
          field: 'shopId',
          data: [],
          onSearchCallback: this.getShopList,
        },
      ],
      moduleName: 'receivingRecord',
      itemList: 'shopId',
    });
    this.printModel = new PrintStore();
    this.galleryId = await getGalleryId();
  };
}
